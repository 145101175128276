<template>
  <div>
    <el-card>
      <div slot="header">
        <span>不可提现余额</span>
      </div>

      <el-form :inline="true" label-width="120px" size="mini">
<!--        <el-form-item>-->
<!--          <el-button type="primary" @click="queryBasic()">查询</el-button>-->
<!--        </el-form-item>-->
      </el-form>
      <div>
        <el-table
          :max-height="500"
          :height="500"
          :data="tableData"
          border
          ref="multipleTable"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="notMoney" label="不可提现余额(元)"></el-table-column>
          <el-table-column prop="cashNum3" label="目前系统提现比例(%)"></el-table-column>
          <el-table-column prop="cjFrozen" label="目前系统冻结时间(天)"></el-table-column>
<!--          <el-table-column prop="status" label="状态">-->
<!--            <template slot-scope="scope">-->
<!--              <span v-if="scope.row.status===1">已解冻</span>-->
<!--              <span v-if="scope.row.status===0">未解冻</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: "no-price-list",
    data() {
      return {
        tableData: []
      }
    },
    methods: {
      queryBasic() {
        this.openLoadingView();
        this.$axios_supermallData.get('hnjxjy-core/eduStreamFrozen/queryNotAllEduStreamFrozen').then(response => {
          if (response.data.code === 2000) {
            this.tableData = [response.data.data];
          }
        })
        this.loadingView.close();
      }
    },
    mounted() {
      this.queryBasic();
    }
  }
</script>

<style scoped>

</style>
